import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import MyNavBar from "./components/MyNavBar";
import "./pages/pages.css"

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./pages/Home.jsx";
import SpeakLikeYou from "./pages/SpeakLikeYou.jsx";
import CogginSketchbook from "./pages/CogginSketchbook.jsx";
import Blog from "./pages/Blog.jsx";
import CreativeSpeak from "./pages/SLYproducts/CreativeSpeak.jsx";
import IntroduceYourself from "./pages/SLYproducts/IntroduceYourselfWithConfidence.jsx";
import SpeakWConfidence from"./pages/SLYproducts/SpeakWithConfidence.jsx";
import OnetoOne from "./pages/SLYproducts/OnetoOne.jsx";
import DocReview from"./pages/SLYproducts/DocReview.jsx";
import Abandoned from "./pages/Abandoned.jsx";
import Fer from "./pages/fer.jsx";
import ImmigrantsPlace from "./pages/posts/ip.jsx";
import GeorgiPost from "./pages/posts/gc.jsx";

function App() {
  return (
    <>
      <MyNavBar />
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/speaklikeyou" component={SpeakLikeYou} />
        <Route path="/cogginsketchbook" component={CogginSketchbook} />
        <Route path="/blog" component={Blog} />
        <Route path="/creativespeak" component={CreativeSpeak}/>
        <Route path="/introduceyourselfwithconfidence" component={IntroduceYourself}/>
        <Route path="/speakwithconfidence" component={SpeakWConfidence}/>
        <Route path="/onetoone" component={OnetoOne}/>
        <Route path="/documentreview" component={DocReview} />
        <Route path="/abandoned" component={Abandoned}/>
        <Route path="/fernandomariano" component={Fer}/>
        <Route path="/immigrantsplace" component={ImmigrantsPlace}/> 
        <Route path="/georginacastagneto" component={GeorgiPost}/>
      </Router>
    </>
  );
}

export default App;
