import React from "react";



import "./Footer.css";

var currentYear = new Date().getFullYear();

function Footer(props) {
  return (
    <footer>
          
           <div className="footer-section">
           <a href={props.link}><p>Back to top</p></a> 
        <p>Sara Coggin {currentYear} </p>
      </div>
    </footer>
  );
}

export default Footer;
