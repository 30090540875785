import React from "react";
import Product from "../../components/Product.jsx";
import Footer from"../../components/Footer.jsx";

function IntroduceYourself(){ 
    return (
        <div> 
            <Product 
            introitem1="Imagine if you could walk into any situation and feel confident."
            introitem2="Imagine if you could find the right words to express yourself and all your amazing ideas."
            introitem3="Imagine if job interviews, pitching your projects and approaching new people stopped causing anxiety and became something you actually wanted to do."
            
            title="Introduce Yourself With Confidence"
            img="../../imgs/seasm.JPG"
            list1title="It’s a 4 week course that will:"
            list1item1="Teach you how to write an introduction that you can use to pitch your project or introduce yourself to new people."
            list1item2="Provide you with techniques and strategies to help you become more confident."
            list1item3="Support you to find the right words to express yourself,  with all your personality, instead of talking like a robot that swallowed a textbook."
            list1item4="Improve your speaking and writing skills so you can stop repeating the same mistakes and start to communicate clearly and powerfully."
            list2title="The details…"
            list2item1="Approximate time investment:
            3 – 4 hours per week for 4 weeks
            "
            list2item2="Price:
            €110 (payable at the start of the course).
            "
            list2item3="September start date: Monday 6th September May (the deadline to sign up is Friday 3rd September)"
            list2item4="July start date: Monday 4th October (the deadline to sign up is Friday 1st October)" 
           signup="To sign up for the course or to ask for more information, just send me an email via the link on the homepage."/> 
<Footer link="./introduceyourselfwithconfidence"/>
            </div>

    );
}

export default IntroduceYourself; 