import React from "react";
import Product from "../../components/Product.jsx";
import Footer from"../../components/Footer.jsx";


function CreativeSpeak() {
    return (
        <div>
            <Product 
            introitem1="Do you ever wish you could press pause?"
            introitem2="I think conversation classes and language exchanges are great for helping you to develop essential speaking and listening skills, and learn to keep up in a real-life, fast-paced conversation.  "
            introitem3="But they’re not enough. Sometimes you need to slow things down. Not to simplify.  Actually, to do the opposite. To go deeper. To challenge yourself to think before you speak."
        introitem4="Wouldn’t it be great if you could have an amazing conversation, in slow motion?"
       
                    title="Creative Speak"
            img="../../imgs/radiosm.JPG"
            
            list1title="We will help you to…"
            list1item1="Identify patterns in your speaking and help you break the cycle of any mistakes. "
            list1item2="Challenge you to improve your language to create interesting and powerful sentences. "
            list1item3="Work intensely on your pronunciation to ensure your speech can be understood clearly and that your words will have an impact."
            list1item4=""
            list2title="The details:"
            list2item1="Each week you will receive the theme and a set of prompts. "
            list2item2="You will make 3 recordings per week (each recording can be up to five minutes long)."
            list2item3="Within 48 hours you will receive detailed feedback and suggestions."
            list2item4="This course is aimed at people with at least an intermediate level of English but it’s completely adaptable so you can take the course regardless of your learning experience."
            list2item5="The price of the course is €50"
            outroitem1="Take a pause."
            outroitem2="A pause in the conservation that gives you the room you need to think, to grow and to improve."
            outroitem3=" A pause in your day."
            outroitem4="A chance to speak in English that you will look forward to, that expands your creativity, and isn’t another task that you need to grit your teeth and get through."
            signup="To sign up for the course or to ask for more information, just send me an email via the link on the homepage."
                         />
                         <Footer link="./creativespeak"/>
                        
        </div> 
    
    );
}
export default CreativeSpeak;