import React from "react";
import "./pages.css";
import Footer from "../components/Footer.jsx";
import audioThing from "../audio/feraudio.mp3";
import image from "../imgs2/saraandfer.png";
import imageTwo from "../imgs2/ferheadshot.jpg";



function Fer() {

 
    return (
        <div>
        <div className="section">
        <h1> Being an international storyteller and the magic of languages | Interview with Fernando Mariano </h1>
          <audio
        controls
        src={audioThing}>
            Your browser does not support the
            <code>audio</code> element.
    </audio>
    <a href="https://docs.google.com/document/d/17YTM2QLDOurr3GTORP7hbcn7gc8T3qhPYz-AXietxdU/edit?usp=sharing">
<p>
  Click here to access the full transcript.</p>
</a>
<p>Intro and outro music by Florin.</p>
<img src={image} alt="" height="200rem" ></img>
</div>
              
     <div className="section">
     <img src={imageTwo} alt="" height="300rem" ></img>
     <a href="https://www.instagram.com/thefernandomariano/">
       <p>@thefernandomariano</p>
     </a>
     <a href="https://www.artistbusinessschool.co.uk/">
       <p>The Artist Buisness School</p>
     </a>
     </div>

        
    

        <Footer/>
        </div>
    )

}



export default Fer;