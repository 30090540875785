import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "./Carousel.css";

function MyCarousel(props) {
      
    return (
      <div className="container">
        <Carousel  >
          <Carousel.Item>
           
          <p>{props.test1}</p>
            <h3>{props.testTitle1}</h3>
            <p className="i">{props.personInfo1}</p>
           

          </Carousel.Item>
          <Carousel.Item>
   
            <p>{props.test2}</p>
            <h3>{props.testTitle2}</h3>
            <p  className="i">{props.personInfo2}</p>
           
          </Carousel.Item>
          <Carousel.Item>
           
            <p>{props.test3}</p>
            <h3>{props.testTitle3}</h3>
            <p  className="i">{props.personInfo3}</p>
            
          </Carousel.Item>
          <Carousel.Item>
    
            <p>{props.test4}</p>
            <h3>{props.testTitle4}</h3>
            <p  className="i">{props.personInfo4}</p>
            
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
  
export default MyCarousel;