import React from "react";

import Divider from "@material-ui/core/Divider";
import"./Product.css";

function Product(props) {
   return (
       <div className="section">
           <div className="productSection">
           <ul className="list">
           <li>{props.introitem1}</li> 
                    <li>{props.introitem2}</li>
                    <li>{props.introitem3} </li>
                    <li>{props.introitem4} </li>
                    <li>{props.introitem5} </li>
                    <li>{props.introitem6} </li>
                    <li>{props.introitem7} </li>
                    <li>{props.introitem8} </li>
           </ul>
           </div>  
            <h1>{props.title}</h1>
            <img className="img" src={props.img} alt=""></img> 
            <div className="productSection">
            <p>{props.intro2}</p>
            </div>
            <div className="productSectionB">
            <h2 className="listTitle">{props.list1title} </h2>
                                 <ul className="list">
                    <li>{props.list1item1}</li> 
                    <li>{props.list1item2}</li>
                    <li>{props.list1item3} </li>
                    <li>{props.list1item4} </li>
                </ul> 
                </div>
                <div className="productSection">
                        <h2 className="listTitle">{props.list2title} </h2>
                        <h2 className="listTitle">{props.list2title2} </h2>
                <ul className="list">
                    <li>{props.list2item1} </li> 
                    <li>{props.list2item2}</li>
                    <li>{props.list2item3} </li> 
                    <li>{props.list2item4}</li>
                    <li>{props.list2item5}</li>
                    </ul> 
                    </div>
                   <div className="productSection">
                    <ul className="list">
                        <Divider />
                    <li>{props.outroitem1}</li> 
                    <li>{props.outroitem2}</li>
                    <li>{props.outroitem3} </li>
                    <li>{props.outroitem4} </li>
                    </ul>
                   
                    <p>{props.signup}</p>
                    </div>
       </div>
   ) ;
}


export default Product;