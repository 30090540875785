import React from "react";
import "./Header.css";
import { Link } from "react-scroll";
import { BsArrowDown } from 'react-icons/bs';
import Grid from "@material-ui/core/Grid";

function Header(props) {
  return (
    <div className="header">

          <h1 >{props.title}</h1>
      
      <p >{props.bio}  </p>
      
      <p>{props.bio2}</p>
      <p>{props.bio3}</p>
      <Grid container direction="row" justify="center" align="center">
      <Grid item xs={12} lg={4}></Grid>
        <Grid item xs={12} lg={4} align="center">
      <div className="miscImg">
        <img id="headerImg"src={props.img} alt="" height={props.height} />
      </div>
      </Grid>
                     </Grid>
           <p>{props.bio3}</p>
    </div>
  );
}

export default Header;
