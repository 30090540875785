import React from "react";
import Product from "../../components/Product.jsx";
import Footer from"../../components/Footer.jsx";


function DocReview() {
    return (
        <div>
            <Product 
            introitem1="Perfect your writing."
            introitem2="Have your document checked and receive actionable feedback on how to improve."
            introitem3=""
            introitem4=""
            title="Creative Document Review"
            img="../../imgs/notebooksm.JPG"
            intro2=""
            list1title="We will…"
            list1item1="Check your document so you can be sure there are no grammar mistakes."
            list1item2="Provide feedback and advice, for example, suggesting improvements to the vocabulary and phrasing so your words will be as powerful as possible. "
            list1item3="Take time to get to know you to make sure that the writing represents perfectly who you are and what you do."
            list1item4=""
            list2title="Is creative document review right for me?"
            list2title2="This service is for you if…"
            list2item1="You want your piece to be put under a microscope and thoroughly combed for errors to avoid being embarrassed by a typo or grammatical mishap. "
            list2item2="You want to boost your writing so it can have maximum impact online. "
            list2item3="You want to confidently publish and share your work."
            list2item4=""
            outroitem1="Once payment is received we’ll complete the review and send you the result within 5 working days. "
            outroitem2="The price is €60."
            outroitem3="We won’t: completely rewrite your piece or write it for you from scratch. We are here to check and improve your writing at the ‘finishing touches’ stage. "
            outroitem4="We won’t: review documents of more than 500 words. If you have a longer piece you’d like help with then send us an email to get a specific quote."
            signup="To access this service, just send me an email (link on homepage) to discuss the document you want to review."
                         />
                         <Footer link="./documentreview"/>
        </div> 
    
    );
}
export default DocReview;