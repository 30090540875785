import React from "react";
import ProjectGridCard from "../components/ProjectGridCard/ProjectGridCard.jsx";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer.jsx";
import "./pages.css";

function Blog() {
  return (
    <div>
      <div className="sectionBlog"> 
     <h1> Blog </h1>
     <p>Interviews with inspirational people + musings on language and creativity + etc. </p>
    </div>
     <div className="blogDiv">
        <Grid container direction="row" justify="space-around" spacing={8}>
          <Grid item sm={12}lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/ferheadshot4.jpg"
                         title="Being an international storyteller & the magic of languages"
              info="An interview with Fernado Mariano"
              link="/fernandomariano"
            />
          </Grid>
          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/georgi2.png"
              title="Instagram marketing in a second language"
              info="An interview with Argentinan illustrator Georgina Castagneto"
              link="/georginacastagneto"
            />
          </Grid>

          
                   
         
          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/virginiaint2.jpg"
              title="Languages, living abroad and waking up at 5:30am"
              info="An interview with Virginia Delgado, Spanish brand designer. 
              (Instagram live)"
              link="https://www.instagram.com/p/CQgkuSPIzOU/"
            />
          </Grid>

          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/creativecondition.jpg"
              title="The Creative Condition"
              info="I was interviewed by Ben Tallon for his wonderful podcast The Creative Condition! We had a great conversation about langauges and expressing yourself confidently as a creative. Click to listen "
              link="https://open.spotify.com/episode/0RAq03W3LxDPsxX2acGlhx"
            />
          </Grid>

          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/milenabannersm.jpg"
              title="Migration, Languages & Mental health"
              info="An interview with Milena of Immigrant's Place"
              link="/immigrantsplace"
            />
          </Grid>

          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/deniseint2.jpg"
              title="Interviewing my mum!"
              info="We talk about learning new skills, language, storytelling and more...
              (Instagram live)"
              link="https://www.instagram.com/p/CRPB_7Uo8sP/"
            />
          </Grid>
         
        </Grid>
        
        </div> 
        <Footer link="./blog"/>
    </div>
  );
}
export default Blog;
