import React from "react";
import "./pages.css";
import Footer from "../components/Footer.jsx";


function Abandoned() {
    return (
        <div>
        <div className="sectionNarrow">
            <h1>Abandoned</h1>
            <img src="../imgs/1sm.jpg" alt="" height={500} />
            <p>For a while I’ve had an obsession with abandoned buildings; once it started, I noticed them everywhere. The half forgotten buildings in varying states of disrepair set me drawing until I ended up with this collection.  </p> 
            <p>Then it was April and we were inside so much and I remembered the same as everyone else that these places matter: where we spend our time and how it looks and the space and the air and the light and how it really matters because it changes how we feel. It changes what we focus on, what we think about and what we feel capable of. </p>
            <img src="../imgs/2sm.jpg" alt="" height={500} />
            <p>Imagine if we didn’t have this space. Imagine the room without this window here. </p>
            <p>I’m reading a lot, more than usual and virus statistics run along the bottom of the screen. I listen to the podcast Mariana recommended (from Fumaça: quarentena na rua).</p> 
            <p>Then I’m on the Shelter website again, scrolling through PDFs: homelessness increasing year on year in the last decade. People literally dying in the street. Because where do you go if you don’t have a home? And how can you lie, propped up, in bed, warm and full and read that? What are you going to do about it? </p>
            <img src="../imgs/3sm.jpg" alt="" height={500} />
            <p>More news, another podcast: on an evening walk I stop at the corner, listening – “so this means that prisoners are essentially being held in cells for 22-24 hours a day,” </p>
            <p>that’s tens of thousands of people in solitary confinement to try to tame the virus in prisons. I go home and watch TV. </p>
            <img src="../imgs/4sm.jpg" alt="" height={500} />
            <p>Reading again. It’s called Primitive Accumulation. Accumulation by dispossession. Now there are even papers written about airbnbfication. The tourists can pay more so the rents go up and those who can’t pay are pushed out. It doesn’t matter that your grandma has lived here all her life.  </p>
            <p>Anyway, it’s not perfect but at least you can afford a room. They’re looking for a flatmate so they want someone, it could be you. I was traipsing up the stairs and my biggest concern was the guitar – should I tell them? Or wait until after? </p>
            <p>Even if they accept me, doesn’t that mean I’m endorsing it?  A foreigner, taking up a space that isn’t mine? </p>
            <p>How much space do I deserve?</p>
            <p>Yeah, I just got out. The sunset from that place is stunning and the station is two minutes away. How much is the deposit?</p>
            <p>Last year you both came up to have a look and we giggled and you said “Sara, this is literally smaller than a prison cell.” (But in the end it was ok, even though the lift was noisy and it was too hot for two people.) </p>
            <img src="../imgs/5sm.jpg" alt="" height={500} />
            <p>I show my Dad that series on Vice: London Rental Opportunity of the Week and La Pesadilla Inmobiliaria  — “Sleep in a Tiny Attic for £1000 a month!” that kind of thing. We laugh as we scroll through the page. A pause as more listings load on my phone. He shakes his head. “Horrific.”</p>
            <p>Remember when we woke up because of the mouse in your room and we could hear scratching but we couldn’t find it! And of course we couldn’t sleep because how could we do anything else but look for it then. I’m not sure what we would have done if we had found it.</p>
            <p>Well, I’m glad that you got out and that you got that flight, even if it meant you moved several time zones away from me.  Your new (old) room looks beautiful and I’m happy you’re safe.</p>
        </div>
        <Footer link="./abandoned"/>
        </div>
    )
}

export default Abandoned;