import React from "react";
import { Navbar, Nav} from 'react-bootstrap';

import "../pages/pages.css";




// animate scroll as scroll

function MyNavBar(){
    return (
        <div>
        <Navbar variant="light"  expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Navbar.Brand href="./">Sara Cogg.in</Navbar.Brand>
        <Nav className="ml-auto">
       
        
        <Nav.Link href="./cogginsketchbook">Coggin Sketchbook</Nav.Link>
        <Nav.Link href="https://aitraveldiary.substack.com/">The AI Travel Diary</Nav.Link>
        <Nav.Link href="https://saracoggin.substack.com/">Blog</Nav.Link>
        
        </Nav>
       </Navbar.Collapse>
      </Navbar>
      </div>

    );
}

export default MyNavBar; 