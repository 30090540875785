import React from "react";
import "../pages.css";
import Post from "../../components/Post.jsx";
import Img from "../../imgs2/milenasm.jpg";

function ImmigrantsPlace() {
    return (
        <div>
            <Post 
            title="Migration, Languages and Mental Health | An interview with Milena of Immigrant’s Place"
            img1={Img}
            intro="I believe that our emotions can have a massive impact on our ability to speak in different languages. Moving to a new country can be amazing, but leaving your home and settling in a new place can also be intensely challenging, especially if you need to communicate in a language that is not your native one. 
            I recently spoke to Milena, creator of Immigrant’s Place – an online mental health resource designed to help people deal with the emotional effects of migrating to a new country. Here you can read the interview, where she talks about her work with migrants, moving to the US and finding the confidence to speak in English. 
            Enjoy!"
            link="https://www.instagram.com/immigrantsplace/"
            linktext="Find Immigrant's Place on Instagram"
            q1="Please can you introduce yourself? "
            a1="Hello, my name is Milena, and I am an immigrant. I was born and raised in Venezuela, but I left my country more than 10 years ago due to its challenging socio-political conditions. I have lived in the different cities in the United States and in Spain, where I studied Philosophy and Social Work. At this moment, I am providing mental health education and mentorship to immigrants and people living abroad through digital platforms. I am also volunteering as a mentor in an international organization that helps young women in Asia to gain leadership skills and advance their careers."
            q2="Why did you decide to create ‘Immigrant’s Place’?"
            a2="My mission with this account is to create an online safe space dedicated to mental health and migration, where we can learn and share our experiences abroad. Although there are many online resources about mental health, there are not so many dedicated to the issues and challenges that specifically affect people who live abroad (for example, learning a new language, dealing with anxiety abroad, ambiguous losses, leaving family or friends behind). In Immigrant’s Place, I talk about those topics with the purpose of letting other immigrants know that we are not alone in our journeys abroad."
            q3="I know that you lived in the US for a few years. How was that experience?"
            a3="My first years in the US had its ups-and-downs. I was fortunate to live in the US with two of my siblings, but the rest of my BIG FAMILY (including parents, grandparents, more siblings, uncles, aunts, cousins…) stayed behind. Even though I moved to the US to advance my career and with a positive mindset, there is an emotional cost that emerges during the process of moving abroad. One of the challenges that I had to navigate was the grief of losing the physical presence of family and friends, as well as spending special occasions, holidays, and difficult live events away from home. At times, those moments can feel isolating, especially during the first years abroad. Regarding cultural differences, I realized that people in the US have a different sense of boundaries in social interactions compared to Venezuelans. At the beginning, I thought Americans were “colder”, but then I realized that they have different cultural norms regarding what may be considered appropriate or inappropriate behaviors with friends, acquaintances, classmates or coworkers. In my home country, the line that divides public and private life sometimes is more blurred. However, something that I really appreciated about American culture is their appreciation for the value of freedom, opportunities, and social change.  "
            q4="How was it to live in an English-speaking country? Did you face any struggles?"
            a4="I did not expect that learning English was going to be such a long, and at-times, anxiety-provoking process! When I arrived in the US, I was already speaking at an intermediate level. Yet, when I saw myself surrounded my native speakers it took tons of effort to feel confident to speak to them. I felt like I was speaking like a 3-year-old child. The most challenging part for me was not to learn grammar or vocabulary, but to feel confident speaking the language."
            q5="You completed your masters in the US, how did you find that experience?"
            a5="Yes, two years ago I finished a master’s degree in Social Work (MSW) with a specialization in Migration and Refugees. I think that was one of the best decisions I have taken in the past few years, because it made me realize that I could use my own personal experiences and the theories that I was learning in school to help other people from this community. As a social worker, I had the opportunity to work as a mental health counselor for unaccompanied immigrant children migrating from different parts of the world to the US. Learning about their dangerous journeys, helping them process their trauma and grief, and helping them to be reunified with their families in the US was one of the most fulfilling experiences I have had. "
            q6="And now you live in Spain! How is that?"
            a6="I arrived in Spain last year, just a few days before the pandemic started. Like most people nowadays, my life in Madrid has been marked by wearing facemasks, using hand sanitizer, and getting used to lockdowns! The positive side is that I have taken this circumstance to start putting my knowledge into digital platforms with the hope of helping other people, regardless of where they are located. "
            q7="What’s your advice to someone who is moving to a new country?"
            a7="Be patient with your own process AND open-minded with the culture and people of your host country. At the beginning, it is normal to compare both cultures, because that is what your brain uses as a framework to understand all the changes that are happening around you. However, there is a level of comparison that is healthy (when you compare both cultures to gain a deeper understanding of both of them), and there is a level of comparison that is unhealthy (when you start criticizing everything about your host culture and idealizing your own).
            My advice is to try to stay away from unhealthy comparison as that will impact your adjustment process and your ability to create new relationships with the locals."
            q8="You present all the content for Immigrant’s Place in both Spanish & English, why did you decide to do that?"
            a8="Language barriers are one of the most common issues we experience when we live abroad. Since I speak both languages, I decided to make my content accessible to people who can understand any of these languages."
            q9="What’s your advice for someone who wants to use English for their project but is scared about doing it?"
            a9="Take baby steps, work at your own pace, and do not pay too much attention to the “critics” around you (they are only looking, not taking the risks that you are taking). I know it can be scary to make new things in English, but it is a learning process. Take one step at a time, starting with the skills or areas that make you feel more comfortable and building up to the skill/areas that seem more challenging for you."
            outro="Thank you so much Milena for answering my questions! It was wonderful to hear about the work you are doing to help others and your advice about settling in a new country. "
       
       /> 
       </div>
    )
}


export default ImmigrantsPlace;