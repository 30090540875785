import React from "react";
import "../pages/pages.css";

function Post(props) {

    return(
        <div>
            <div className="sectionNarrow"> 
            <h1>{props.title}</h1>
            <p><em>{props.intro}</em></p>
            <img src={props.img1} alt=""  className="blogImg"/>
            <a href={props.link}>
            <p>{props.linktext}</p>
            </a>
            </div>
            <div className="sectionNarrow"> 
            <h2>{props.q1}</h2>
            <p>{props.a1}</p>
            <h2>{props.q2}</h2>
            <p>{props.a2}</p>
            <img src={props.img2} alt="" className="blogImg"/>
            <h2>{props.q3}</h2>
            <p>{props.a3}</p>
            <h2>{props.q4}</h2>
            <p>{props.a4}</p>
            <img src={props.img3} alt=""  className="blogImg"/>
            <h2>{props.q5}</h2>
            <p>{props.a5}</p>
            <h2>{props.q6}</h2>
            <p>{props.a6}</p>
            <img src={props.img4} alt=""  className="blogImg"/>
            <h2>{props.q7}</h2>
            <p>{props.a7}</p>
            <h2>{props.q8}</h2>
            <p>{props.a8}</p>
           
            <h2>{props.q9}</h2>
            <p>{props.a9}</p>
            <h2>{props.q10}</h2>
            <p>{props.a10}</p>
            <p><em>{props.outro}</em></p>
            <img src={props.img6} alt=""  className="blogImg"/>             
            <img src={props.img5} alt=""  className="blogImg"/>
            <img src={props.img7} alt=""  className="blogImg"/>
            </div>
        
        </div>
    )
}

export default Post;