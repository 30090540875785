import React from "react";
import Header from "../components/Header/Header.jsx";
import ProjectGridCard from "../components/ProjectGridCard/ProjectGridCard.jsx";
import Grid from "@material-ui/core/Grid";
import Button from 'react-bootstrap/Button'
import Footer from "../components/Footer.jsx";
import "./pages.css";
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';



function CogginSketchbook() {
  return (
    <div>
      <Header
        title="Coggin Sketchbook"
        bio=" Here you can see a selection of comissioned artwork and my personal projects."
        img="../imgs/1sm.jpg"
        height= "600rem"
        link="cta"
        duration={10000}
        offset={-40}
      />
      <div className="productsDiv">
        <Grid container direction="row" justify="space-around" spacing={8}>
          <Grid item sm={12}lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/bcnbooksm.JPG"
                         title="Things I saw in Barcelona"
              info=" A love letter (in sketchbook form) to a city that was my home for a short while. 
              26 pages of original illustrations and writing. "
              link=" https://cogginsketchbook.bigcartel.com/product/things-i-saw-in-barcelona-printed-sketchbook"
            />
          </Grid>
          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/ai.png"
              title="The AI Travel Diary"
              info="Collaboration with Jack Coggin, exploring the creative posibilites of Artificial Intelligence. Our AI recently travelled to Rio de Janeiro, Seville and Rekjyavic. "
              link="https://aitraveldiary.wordpress.com/"
            />
          </Grid>
          <Grid item sm={12}  lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/bsassm.JPG"
              title="Buenos Aires menus"
              link="https://www.instagram.com/p/CFpaw2rMj4z/"
              info="Illustrations of the San Telmo for menus designed by Emily Sheridan for a birthday party organised by Anna Lucia events."
            />
          </Grid>
          <Grid item sm={12} lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/sinksm.jpg"
              title="Sink Magazine"
              link="https://www.sinkmagazine.co.uk/"
              info="A set of illustrations exploring the alienation of work in late-stage capitalism, and the impact of work on our bodies."
            />
          </Grid>
          <Grid item sm={12} lg={4} spacing={3}>
            <ProjectGridCard
            link="/abandoned"
            img="../imgs/2sm.jpg"
              title="Abandoned"
              info="A lockdown project. Illustrations and writing exploring how we are affected by the spaces we inhabit. "
            />
          </Grid>
          <Grid item sm={12} lg={4} spacing={3}>
            <ProjectGridCard
            img="../imgs/manchester.jpg"
            link="https://www.instagram.com/p/CE9SPcNI5WT/"
              title="Manchester"
              info="Prints, cards, cushions and zip purses featuring Manchester landmarks. The town hall, Manchester Cathedral, Afflecks, St Ann's Square, Manchester Art Gallery."
            />
         </Grid>
        </Grid>
      
      <div className= "sectionWide" id="cta">
     <Grid container>

     <Grid item sm={12} lg={4} spacing={3} align="center">
     <div className="buttons">
     
     
     </div>
     </Grid> 
       <Grid item md={12} lg={4} spacing={3}align="center">
       <a href="https://www.instagram.com/p/BwroMD_FYCp/">
                    <img className="miscImg" src="../imgs/sketchbooksm.JPG" alt="" height="650 rem"/> 
          
          </a>
           </Grid> 
              
    <Grid item md={12} lg={4} spacing={3} align="center"> 
           <a href="https://www.instagram.com/cogginsketchbook/">
     <img className="miscImg" src="../imgs/cogskbig.png" alt="" height="650 rem"/> 
     
     </a>
     </Grid>
     
          
     
    
     </Grid>
     </div>
     </div>

      <Footer link="./cogginsketchbook"/>
    </div>
  );
}
export default CogginSketchbook;
