import React from "react";
import "./pages.css";
import Header from "../components/Header/Header.jsx";
import GridCard from "../components/GridCard/GridCard.jsx";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer.jsx";
import MyCarousel from "../components/Carousel.jsx";


function SpeakLikeYou() {
  return (
    <div>
      <Header
        title="Speak Like You"
        bio="A teaching project by Denise and Sara "  
        bio2="We help creative people to grow their confidence and develop their communication skills so they can share their projects with an international audience. We believe that your art should be shared with the world, not hidden away."
        

        img="../imgs/denise.jpg"
        height="400rem"
        link="slycta"
        duration={10000}
        offset={+70}
        
      />
      <div className="productsDiv">
        <Grid container direction="row" justify="space-around" spacing={8}>
          <Grid item xs={12}  lg={6} spacing={3}>
            <GridCard
              title="SPEAK WITH CONFIDENCE"
              price="Free workshop"
              info="A short workshop to help you find your power and give your confidence a boost."
              link="/speakwithconfidence"
              img="../imgs/coffeesm.JPG"
            />
          </Grid>
           {/*
          <Grid item xs={12}  lg={6} spacing={3}>
            <GridCard
              title="INTRODUCE YOURSELF WITH CONFIDENCE"
             
              info=" A four week course that will teach you how to write an introduction and give you the skills to deliver it with confidence.
              "
              link="/introduceyourselfwithconfidence"
              img="../imgs/seasm.JPG"
            />
          </Grid>
          
                    <Grid item xs={12} lg={6} spacing={3}>
            <GridCard
              title="CREATIVE SPEAK"
                            info="A speaking course with creative prompts and personalised feedback."
              link="/creativespeak"
              img="../imgs/radiosm.JPG"
            />
          </Grid>
          <Grid item xs={12}  lg={6} spacing={3}>
            <GridCard
              title="DOCUMENT REVIEW"
              info="Artist statement? About page? Social media profile? We’ll review it for errors and give you suggestions on how to improve."
            link="/documentreview"
            img="../imgs/notebooksm.JPG"
            />
          </Grid>
           */}
          <Grid item xs={12} lg={6} spacing={3}>
            <GridCard
              title="1 to 1 sessions"
              info="Explore 1 to 1 sessions"
              link="/onetoone"
             img="../imgs/handssm.JPG"
            />
          </Grid>
       
        </Grid>
      </div>
<div id="prod">
      <MyCarousel
      test1="I not only had the chance to improve my speaking skills with her help, but she also reminded me that the most important thing is to remember our internal goals. Language shouldn’t scare us – it should be a tool to become what we want!"
      testTitle1="Mariana Gomes"
      personInfo1="Portuguese writer & creator of 'Ok, What's the Next Step?' accountabilty project"
      test2="Sara, you are an inspiring and motivating teacher. The workshop has given me workable, practical and inspiring tools… It was awesome and very original."
      testTitle2="Blanca Presa"
      personInfo2="English student from Madrid"
      test3="The learning process can be different for each person. In the classes of Sara, she never dictates a thing. She always finds a way for you. She is like a guide (a talented and kind guide). You don’t feel stressed and this increases the possibility of success."
      testTitle3="Ozgur Ozhan"
      personInfo3="Artist and writer from Turkey, living in The Netherlands"
      test4="It was a pure pleasure and joy to work with you, Sara! You've proved exactly what you said: introducing myself can me fun and inspiring!"
      testTitle4="Marina Frangipani"
      personInfo4="Fashion designer, wardrope therapist, creator of Inside Out"
      />
      </div>
      <div className="sectionNarrow" id="slycta">
      <Grid container alignItems="center"
  justify="center">
      <Grid item md={12} lg={6} spacing={5} align="center"
  justify="center"> 
           <a href="https://www.instagram.com/speaklikeyou/">
     <img className="miscImg" src="../imgs/slyig.png" alt="" height="650 rem"/> 
     
     </a>
     </Grid>

     <Grid item md={12} lg={6} spacing={5} align="center"
  justify="center"> 
         
     <img className="miscImg" src="../imgs/workshop.jpg" alt="" height="500 rem" /> 
     <p>A live Speak with Confidence Workshop</p> 
         
     </Grid>
     

        </Grid> 
        </div>
     
      <Footer link="./speaklikeyou"/>
    </div>
  );
}
export default SpeakLikeYou;
