import React from "react";
import Product from "../../components/Product.jsx";
import Footer from "../../components/Footer.jsx";


function OnetoOne() {
    return (
        <div>
            <Product 
            introitem1=""
            introitem2=""
            introitem3=""
            introitem4=""
            title="1:1 Sessions"
            img=""
            intro2="Unfortunately, we're not currently taking on any new students. Sign up to my newsletter to keep up to date with any open slots."
            // list1title="5 Lessons Package"
            // list1item1="This package includes 5 x 50 minute sessions and chat support via WhatsApp or Signal so you have the chance to ask any questions throughout the duration of the sessions."
            // list1item2="€150 "
            // list1item3=""
            // list1item4=""
            // list2title="1 session (60 minutes)"
            // list2item1="This option is ideal if you have a specific short term goal – for example a presentation, interview or event to prepare for, or if you want to give a boost to one specific area of your communication skills. (For example pronunciation or vocabulary). "
            // list2item2="This option includes a recording of the session, a document with notes from the session and 1 week of support via messages on WhatsApp or Signal in case you have any questions after the session. "
            // list2item3="€40"
            // outroitem1="If you’re interested in either of these options please send me an email (link on the homepage) to check for availability."

                         />
        <Footer link="./onetoone"/>
        </div> 

    
    );
}
export default OnetoOne;