import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./ProjectGridCard.css";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
   
    media: {
      height: 300,
    },
  });

function ProjectGridCard(props) {
    const classes = useStyles();
  return (
             <Card className="card">
    <a href={props.link}>  
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.img}
               />
        <CardContent>
          <h2 align="center">{props.title}</h2>
         <p align="center">{props.info}</p>
        
        </CardContent>
      </CardActionArea>
      </a>
        </Card>
        
  );

}

export default ProjectGridCard;