import React from "react";
import "../pages.css";
import Post from "../../components/Post.jsx";
import Img2 from "../../imgs2/georgi1.jpg";
import Img1 from "../../imgs2/georgi2.png";
import Img3 from "../../imgs2/georgi3.png";
import Img4 from "../../imgs2/georgi7.jpg";


function GeorgiPost() {
    return (
    <div>
        <Post 
        title="Online Marketing in a Second Language | Interview with Georgina Castagneto "
        intro="My goal with my students is not just to improve their technical English skills, it’s also to change how they feel when they use the language. I believe that getting comfortable using English so that you can express yourself authentically and show off your creativity, can create many opportunities. 

        I saw an Argentinian artist doing just that – she chose to present her artwork in English so she was able to reach an international audience, but did this in a stylish and playful way that stayed true to her artistic identity.
        
        In addition to creating beautiful illustrations, Georgina Castagneto  showcases her work and her creative process on Instagram, so I was curious to talk to her and find out how she was able to do this so well in a second language, and what she thinks about the pressure around communicating in English."
        img1={Img1}
        link="https://www.instagram.com/georgctgn/?hl=en"
        linktext="Find Georgina on Instagram @georgctgn"
        q1="To start with, can you tell me a little bit about yourself?"
        a1= "I’m a twenty year old Graphic Design student from Buenos Aires, Argentina. I also work as a freelance illustrator doing all kinds of commissions and having the chance to work with people from all over the world."
        q2="Why did you decide to become an artist?"
        a2="Since I can remember I’ve been extremely interested in art. My dad,  in his thirties,  used to be an oil painter. I believe he is still an artist, even though he doesn’t paint anymore. It is on him. All my childhood memories are full of art books, hours drawing nonsense and watching him paint. 
        When I was  8 a close neighbor, Daiana, offered me to come to her studio to learn more about drawing and other fun techniques. I had a great time there doing all kinds of art projects and having fun in general. When I started high school, I lost interest in drawing for a while, like two years or so. I had a hard time there but then somehow, I started drawing again. So since 2015 I’ve been drawing non-stop and I can’t see myself stopping ever again."
        q3="What’s your language learning background? Do you speak any other languages?"
        a3="My mother language is Spanish, I speak English and since I’m half Italian I also speak a little bit of Italian, but I have to say I listen and read better than I speak. I had English all through school, since kindergarten but the level and the teaching mythology was not great, so my parents sent me to an English institute to have a better understanding of it. They don’t speak English, but they always told me the importance of learning it since it would always open doors, all kind of doors; jobs opportunities, relationships, friendships, interesting conversations…and as a ‘defense tool/mechanism’ too; if I ever find myself in a bad situation or an emergency where nobody speaks my language, I would be able to communicate and hopefully make my way out of there."
        q4="You’re based in Argentina. Why do you choose to present your work in English?"
        a4="I like the idea of reaching out to as many people as possible. Not because I want to become a famous illustrator/artist but because I like connecting with different people from different backgrounds, history, culture. I think that’s fascinating and makes you grow in so many ways. Using English to present my work has given me that. All the commissions I receive are mostly from people outside Argentina and I love to think that a little part of me is somewhere else far away like in Germany, the USA, Greece, etc. It’s really exciting."
        q5=" Do you think it’s necessary for artists and creative people to be able to communicate in English?"
        a5="With this I don’t necessarily say that you need to present your work in English or use English in general, it is your own choice, whether you feel like it or not. It all depends what you’re trying to accomplish. We all face different art paths, and that’s okay. Either way is totally correct and valid. However, I do think using English would expand your horizons, not just as an artist but as a person, and it’s a huge tool to have. Our art field, as many others, is full of talented and amazing people and I can’t imagine losing the chance to connect with them, even if I only ask them what pen they are using! English is our common ground."
        q6="I think your posts are full of personality and I love it when you share your creative process in your stories. How do you decide what to share and what to write on Instagram?"
        a6="Thank you! Certainly, I don’t have a solid plan. Not at all. I’m still figuring that out. Sometimes I overthink everything too much; should I post this or not, what I should share, do I need to write long posts descriptions or just short ones, should people see my face more often? Hundreds of questions and now more than ever since Instagram went crazy with all those recent updates they’ve made. However, I always try to stay true to myself and only post/share what feels good to me not what’s expected of me to do. That’s how I have more fun. 

        In the last year I’ve been sharing through my stories little videos of my creative process. I started filming them out of curiosity to see how they look in a time lapse but then I thought it would be nice to share them to my audience for them to see more of the “backstage” of my illustrations. I think we can call that a strategy – maybe that’s the only solid one I have at the moment."
        q7="How do you feel when you have to write and speak in English? What helps you to overcome your nerves? "
        a7="This is a hard one. Speaking English is always a challenge, not only English but any language that  is not yours. Especially at first when you can tell you are making too many mistakes. I still make them here and there (probably I’ve made ones even in this interview) and yes, it’s embarrassing but I’ve learnt not to be afraid of them to keep my mouth shut. 

        At the moment I’m working on my Italian and learning Norwegian from scratch and let me tell you the fear of speaking is real, but I’m always reminding myself that we are all learning here."
        q8="What’s your advice for an artist who wants to promote their work but is afraid of communicating in English, in case they make a mistake?"
        a8="Practice! It’s all about practice! Talk in English alone, with a relative who may not understand what you are saying, with a complete stranger, it doesn’t matter.  

        I’m often afraid to make mistakes myself but who cares really? The most important thing is that you are trying and you’re not letting that fear control you. Maybe you may not be noticing it but you might be doing just fine"
        
        
        outro="Thank you so much for answering my questions! 

        Here is Georgina’s Instagram.
        
        Personally I find that when I start to speak a new language, although I might be able to talk about basic things but expressing my emotions or anything artistic is really difficult. 
        
        I think Georgina’s advice is really great: 
        
        Think about the benefits of presenting your work in a new language, and decide if it’s for you or not. 
        Know that we are all learning, we’re all in the same boat and we will improve our mistakes as we go
        Don’t let fear of speaking in a new language stop you from sharing your wonderful creations with the world! All images included in this post belong to Georgina Castagneto. "
        img2={Img2}
        img3={Img3}
        img4={Img4}
              
        />
    </div> 
    )
}

export default GeorgiPost; 