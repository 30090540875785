import React from "react";
import "./pages.css";
import Button from 'react-bootstrap/Button';
import Footer from "../components/Footer.jsx";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Grid from "@material-ui/core/Grid";



function Home() {
  return (
    <div>
        
     <div id="about" className="sectionTop">
              <h1> Hi, I'm Sara. </h1>
            <p>I am an artist, writer, and teacher who enjoys going on adventures and telling stories. <br></br> <br></br>
At the moment, I spend most of my working day creating content for SaaS companies, building the <a href="https://aitraveldiary.substack.com/">AI Travel Diary</a>, or writing for<a href="https://saracoggin.substack.com/"> my personal blog.</a> 

</p> 
           
       <div className="buttons"> 
       <Grid container direction="row"  justify="space-around"spacing={5}> 
       <Grid item sm={12} md={12} lg={12} spacing={1}><Button className="singleButton"variant="outline-dark" size="lg" mr="1" href="https://saracoggin.substack.com/"> <LocalFloristIcon/> Join my mailing list</Button></Grid>
              <Grid item sm={12} md={12} lg={12} spacing={1}><Button className="singleButton" variant="outline-dark" size="lg" mr="1" href="https://www.linkedin.com/in/coggin-sara/"> <LinkedInIcon/>  Follow me on LinkedIn</Button></Grid>
       <Grid item sm={12} md={12} lg={12} spacing={1}><Button className="singleButton" variant="outline-dark" size="lg" mr="1" href="mailto:cogginsara@gmail.com"> <EmailOutlinedIcon/>  Email me</Button></Grid>
      
      </Grid>
      </div> 
    
    <div className="miscImg" align="center">
        <img src="../imgs/sarabeach.jpg" alt="" height="500rem" />
      </div>
    
 
<h3>Fun facts you don't need to know:</h3>
<p>
I speak Spanish and Portuguese — right now I'm learning French and Farsi.</p>
<p>
  I love to play guitar, sing, and dance salsa.</p> <p> I can gut and fillet a fish. </p> <p> My favourite cheese is Greenfields Crumbly Lancashire (on toast).</p> 
  </div>
      
     
             
     
      <Footer link="./" />
    </div>
  );
}

export default Home;
