import React from "react";
import Footer from"../../components/Footer.jsx";
import "../pages.css"


function SpeakWConfidence() {
    return (
        <div>
            <div className="section">
          <h1> Speak With Confidence</h1>
          <img className="miscImg" src="../../imgs/coffeesm.JPG" alt="" height="400px"></img> 
          <p> A 25 minute workshop to help you kickstart your confidence. </p> <br></br><p>To access the free workshop & downloadable workbook, just send me an email (link on homepage). </p>
          </div>

                         
                         <Footer link="./speakwithconfidence"/>
        </div> 
    
    );
}
export default SpeakWConfidence;